import HeaderSection from "../components/Header";
import FooterSection from "../components/Footer";
import ThankYouSection from "../components/ThankYouSection";

const ThankYouPage = () => {
    return (
        <>
            <HeaderSection />
            <ThankYouSection />
            <FooterSection />
        </>
    );
};

export default ThankYouPage;
