import HeaderSection from "../components/Header"
import FooterSection from "../components/Footer"
import TermsCondition from "../components/TermsCondition"


const AppPage = () => {


    return (
        <> 
            <HeaderSection />

            <TermsCondition />

            <FooterSection />
        </>

    )
}

export default AppPage