import PrivacyPolicySection from "../components/PrivacyPolicySection"
import FooterSection from "../components/Footer"
import Header from "../components/Header"


const AppPage = () => {


    return (
        <>
           <Header />

            <PrivacyPolicySection />

            <FooterSection />
        </>

    )
}

export default AppPage